.top{
    margin-top: 84px;
}
.h65{
    height: 65px;
}
.question_answer{
   padding-top: 10px;
   
}
.info_question{
    height: 170px;
}
textarea{
    border-radius: 8px;
}
.answer >h2{
    margin-bottom: 50px;
}
.answer >p{
    margin-bottom: 35px;
}
.answer{
    box-shadow: 0px 0px 4px 2px rgba(1, 0, 0, 0.1);

}
.answer{
    border-radius: 7px;
}
.invalid{
    border: 1px solid #e01e5a !important;
    background:#fce9ef !important;
}
