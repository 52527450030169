.black{
    color: black !important;
}

.link:hover {
  color: black; 
  text-decoration: none;
}
/* #FE8402 */ 
/* #516CF0 */
.btn{
    background-color:#516CF0!important ;
    width: 200px;
    height: 40px;
    /* margin-left:  30px; */
 
}
.btn:hover{
    background-color: #FE8402!important;
    border: #FE8402!important;
}
.navbar{
    height: 80px;
    z-index: 1;
   
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    
}