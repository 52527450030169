.first-name{
    width: 35% !important;
    margin-right:5%;
}
.last-name{
    width: 35% !important;
    margin-left: 5%;
}
.invalid{
    border: 1px solid #e01e5a !important;
    background:#fce9ef !important;
}
.hide{margin-right: -25px;}