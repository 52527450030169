
.login__container{
    display: flex;
    flex-direction: column;
 border-radius: 5px;
box-shadow: 0px 0px 8px 2px rgba(1, 0, 0, 0.1);
 padding: 20px;
 background-color: white;
padding-top: 100px;
margin-right: 5px;
text-align: center;
 padding-bottom: 100px;
 margin-bottom: 40px;

}
.login__container>h4{
    padding-bottom: 30px;
}
.login__container> p{
    padding-bottom: 20px;
}
.login__container > form > input{
    height: 45px;
    margin-bottom: 10px;
    background-color: white;
    width: 80%;
    padding-bottom: 15px;
    border: 1.3px solid #ccc;
    border-radius: 5px;
     outline: none;

    
}



.login__container > form > input:focus {
  border-bottom: 2px solid #FE8402; /* Border bottom for focus state */
transition: border-color 0.5s ease-in-out /* Added padding-bottom transition */

} 

.login__signInButton{
    border-radius: 5px;
   
    width: 80%;
    height: 36px;
    border: 1px solid;
    color: white;
    margin-top: 10px;
    background-color:#FE8402!important 
   

}


/* .login__signInButton{
    background-color:#FE8402!important 
} */

.create{
    color: #FE8402;
    text-decoration: none;
}
.create:hover{
text-decoration: underline;
cursor: pointer;
}

.invalid{
    border: 1px solid #e01e5a !important;
    background:#fce9ef !important;
}
.hide{margin-right: -25px;}