.home{
 background-image: url('../images/bg-svg-f.svg');
  background-size: cover;
  background-position: center;
 
  z-index: 0;
  margin-top: 81px;
  background-repeat: no repeat;
  
  
}

.con{
  padding-top: 140px;
}
.info{
  max-width: 500px;
  margin-left: 5px;
  padding-top: 50px;
}
.about{
  color: #FE8402;
  text-decoration: none;
}
.pl{
  margin-bottom: 45px;
  margin-top: 25px;
}
.works{
  border-radius: 5px;
  padding: 3px 3px ;
    width: 180px;
    height: 36px;
    border: 1px solid;
    color: white;
    margin-top: 10px;
    background-color:#FE8402!important 

}




/* Default styles */
.con {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Specific styles for sign-in/sign-up components */
.show-sign-in {
  transform: translateX(0%);
}

.show-sign-up {
  transform: translateX(-100%);
}
